import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Breadscrum from "../components/Breadscrum";
import Block from "../components/Block";


const OpKremaceInner = styled.div`
  width: calc(100% - 50px);
  margin: 0 auto;
  padding-bottom: 30px;
  max-width: 1150px;

  h1 {
    font-family: Ivar;
    font-size: 26px;
    margin-bottom: 30px;
    color: #000000;
    margin-top: 25px;

    @media (min-width: 900px) {
      margin-top: 50px;
      margin-bottom: 40px;
      font-size: 30px;
    }
  }

  h2 {
    font-family: Visuelt-Medium;
    font-size: 22px;
    margin-bottom: 25px;
    margin-top: 40px;
    color: #000000;
  }

  p {
    margin-bottom: 20px;
    font-family: Visuelt-Regular;
    line-height: 25px;
    font-size: 16px;
  }
`;


const ObchodniPodminkyKremace = ({ data }) => {

  return (
    <Layout>
      <SEO
        title="Términos y condiciones"
        description="Los términos y condiciones son parte integrante del contrato de agencia y cualquier disposición diferente en el contrato de agencia prevalecerá sobre las disposiciones de los términos y condiciones."
        noindex={true}
      />

      <Breadscrum
        visible={false}
        stranky={[
          {name: "Servicio fúnebre", url: "/"},
          {name: "Términos y condiciones", url: "/obchodni-podminky"}
        ]}
      />

      <OpKremaceInner>
        <h1>Términos y condiciones de eTanatorio.es</h1>
        <div className="podminkyInner">
        <p>Las presentes Condiciones Generales (en adelante, "Condiciones Generales") del portal eTanatorio.es, operado por la persona jurídica Goodbye s.r.o., que opera en virtud de la Ley de Licencias Comerciales, con domicilio social en Na Kopci 164, 251 62, Rosice, número de identificación CZ08319588 (en adelante, "Intermediario"), regulan de acuerdo con las disposiciones del artículo 1751 (1) Coll, Código Civil, en su versión modificada (en adelante, "Código Civil"), los derechos y obligaciones de las partes contratantes que surjan en relación con un contrato de prestación de servicios celebrado entre el intermediario y el interesado a través de la interfaz web operada por el intermediario o sobre la base de dicho contrato. La interfaz web es operada por el intermediario en Internet en www.eTanatorio.es.</p>
        <p>Las condiciones generales forman parte integrante del contrato de corretaje y las disposiciones derogatorias del contrato de corretaje prevalecen sobre las disposiciones de las condiciones generales.</p>
        <p>El objeto del servicio es la prestación de servicios funerarios ofrecidos por el intermediario a través de la interfaz web. La cartera de servicios y su precio vienen determinados por la oferta del intermediario (paquete de servicios individuales) publicada en la interfaz web eTanatorio.es. El intermediario tiene derecho a modificar o completar las disposiciones de estas condiciones. Los nuevos términos y condiciones son efectivos desde la fecha de su publicación en la interfaz web de eTanatorio.es. La relación contractual entre el intermediario y el interesado se regirá por las condiciones vigentes en el momento de la entrega del pedido por parte del interesado al intermediario. Para solicitar los servicios, el interesado debe rellenar el formulario de pedido en la interfaz web de la tienda (en adelante, "pedido"). Si el interesado requiere servicios más allá de la oferta del intermediario publicada en la interfaz web de eTanatorio.es, deberá indicar sus necesidades en el formulario de pedido. El Intermediario hará un presupuesto al interesado por correo electrónico, que deberá ser confirmado por el interesado por correo electrónico. Los servicios acordados, incluido el presupuesto, deben confirmarse por escrito.</p>
        <p>Antes de enviar el pedido al intermediario, se permitirá al interesado comprobar el pedido y modificar los datos introducidos en el mismo, incluso en lo que respecta a la capacidad del interesado para detectar y corregir los errores cometidos al introducir los datos en el pedido. El interesado deberá enviar el pedido al intermediario haciendo clic en el botón correspondiente. La información proporcionada en el pedido es considerada correcta por el intermediario. Inmediatamente después de recibir la orden, el corredor confirmará su recepción al interesado por correo electrónico a la dirección de correo electrónico del interesado indicada en la interfaz de usuario o en la orden (en lo sucesivo, la "dirección de correo electrónico del interesado"). Dependiendo de la naturaleza del pedido (requisitos específicos), el intermediario siempre tiene derecho a pedir al interesado una confirmación adicional del pedido (por ejemplo, por escrito).</p>
        <p>
            <strong>Ejecución de los servicios</strong>
        </p>
        <p>
            El servicio funciona en línea las 24 horas del día.
        </p>
        <p>
        El pago del servicio y la realización del pedido en la interfaz web son requisitos previos para el inicio del servicio.
        </p>
        <p>
            <strong>La parte interesada</strong>
        </p>
        <p>
        El interesado puede ser una persona jurídica o una persona física que haya cumplido los 18 años de edad y tenga plena capacidad de obrar en relación con las presentes Condiciones Generales, dé su consentimiento al tratamiento de los datos personales, cumplimente debidamente el pedido en eTanatorio.es y abone al agente la cuota en la cuantía que sea requisito indispensable para el inicio del servicio.
        </p>
        <p>
            <strong>Conclusión del contrato</strong>
        </p>
        <p>
            Al completar el pedido y enviarlo, el interesado confirma que conoce las condiciones y las acepta. El pedido es un proyecto de contrato para la prestación de servicios funerarios. El contrato de mediación de servicios funerarios se perfecciona en el momento en que la orden del interesado es aceptada por el mediador, es decir, cuando el interesado recibe la aceptación de su orden por parte del mediador.
        </p>
        <p>
            En el pedido, el interesado está obligado a proporcionar información exacta y veraz. Si se produce un cambio en los datos del licitador, éste está obligado a notificar inmediatamente dicho cambio al corredor por correo electrónico a podpora@eTanatorio.es. El intermediario tiene derecho a rechazar el pedido y a no prestar el servicio solicitado si puede creer razonablemente que el interesado incumple estas condiciones o las ha incumplido en el pasado.
        </p>
        <p>
            <strong>Garantías</strong>
        </p>
        <p>
        El intermediario es responsable ante el interesado de que los servicios prestados sean conformes con el contrato, en particular de que no presenten defectos. La conformidad con el contrato significa que los servicios suministrados se han prestado de acuerdo con el contrato y que el servicio tiene la calidad y las prestaciones exigidas por el contrato, descritas por el intermediario, el proveedor o su representante, o esperadas a partir de la información facilitada por ellos, o, en su caso, la calidad y las prestaciones habituales en este tipo de servicios, de modo que se ajusta a las exigencias de la normativa legal.
        </p>
        <p>En caso de que el servicio no sea conforme con el contrato en el momento de su aceptación por parte del interesado, es decir, que sea defectuoso (en lo sucesivo, "incumplimiento del contrato"), el cliente tendrá derecho a que el intermediario subsane el defecto reparándolo gratuitamente y sin demora indebida; en caso de que dicho procedimiento no sea posible, el cliente podrá exigir una reducción razonable del precio o rescindir el contrato. Esto no se aplica si el cliente conocía el incumplimiento del contrato antes de hacerse cargo del servicio o si él mismo causó el incumplimiento del contrato. Un conflicto con el contrato que se manifieste dentro de los seis (6) meses siguientes a la fecha de aceptación del servicio se considerará existente en el momento de la aceptación y entrega del servicio, salvo que sea contrario a la naturaleza del asunto o se demuestre lo contrario.
        </p>
        <p>
        Los derechos del Cliente derivados de la responsabilidad del intermediario por defectos serán ejercidos por el Cliente por escrito al intermediario en su domicilio social, por correo electrónico a podpora@eTanatorio.es.

        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>Contrato de consumo</strong>
        </p>
        <p>
        Un contrato de consumo es un contrato de consumo si el interesado es un consumidor, es decir, si es una persona física y la prestación del servicio es ordenada por el interesado fuera del ámbito de su actividad empresarial o fuera del ámbito del ejercicio independiente de su profesión.

        </p>
        <p>
        En el caso de un contrato de consumo, el interesado está sujeto a la protección del consumidor según la legislación.

        </p>
        <p>
        Si el interesado es un consumidor y surge un conflicto entre el intermediario y el interesado derivado del contrato que el intermediario y el interesado no pueden resolver directamente, el interesado tiene derecho a remitir el conflicto a la Autoridad de Inspección Comercial Checa (www.coi.cz). Los derechos de los consumidores también son defendidos por las asociaciones de intereses de los consumidores y otros organismos de protección de los consumidores. Este derecho puede ser ejercido por el interesado en el plazo de un año a partir de la fecha en que se ejerció por primera vez el derecho objeto de este litigio de consumo ante el intermediario.

        </p>
        <p>
            <strong>Precio y condiciones de pago</strong>
        </p>
        <p>
        El precio del servicio de mediación es un precio contractual y se indica en el sitio web eTanatorio.es. Al enviar un pedido, el interesado confirma su aceptación del precio.

        </p>
        <p>
        El precio puede pagarse: sin efectivo, con tarjeta de crédito, o

        </p>
        <p>
        por transferencia bancaria al número de cuenta del corredor 1259749621/0100, o,
        </p>
        <p>
        por cualquier otro método ofrecido por la pasarela de pago.
        </p>
        <p>
        El interesado reconoce que el pago del precio del servicio es un requisito previo para el inicio del mismo.
        </p>
        <p>
        En el caso de un pago no monetario, debe indicarse el símbolo variable del pago, y el pago del precio se produce en el momento de su abono en la cuenta del intermediario.
        </p>
        <p>
        El intermediario emitirá una factura fiscal al candidato tras el pago del precio de los servicios y la enviará en formato electrónico a la dirección de correo electrónico del candidato.
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>Retirada del contrato</strong>
        </p>
        <p>
        El pedido puede ser anulado y el contrato puede ser retirado sólo en los términos y condiciones especificados en estas condiciones.
        </p>
        <p>
        Se puede cancelar un pedido enviando un correo electrónico de cancelación a podpora@eTanatorio.es. En el correo electrónico, el interesado está obligado a indicar el número de pedido.

        </p>
        <p>
        El interesado puede desistir del contrato de consumo celebrado entre el intermediario y el interesado en un plazo de 14 días a partir de la fecha de celebración del contrato de intermediación. La notificación de retirada debe enviarse al correo electrónico podpora@eTanatorio.es. El intermediario confirmará la recepción de la notificación de desistimiento por correo electrónico sin demora. Al desistir del contrato, éste quedará anulado desde el principio y se considerará como si no se hubiera celebrado. En caso de desistimiento del contrato, el intermediario deberá reembolsar el pago realizado y recibido del posible comprador en virtud del contrato en un plazo de 14 días a partir del desistimiento del contrato a la cuenta desde la que se envió el pago al intermediario o a la cuenta especificada en el desistimiento. De acuerdo con el artículo 1837 del Código Civil, no se puede desistir del contrato si la prestación se ha realizado con el consentimiento expreso del interesado antes de la expiración del plazo de desistimiento; el interesado reconoce esta consecuencia al aceptar la realización de la prestación antes de la expiración del plazo de desistimiento. En este caso, el pago recibido no puede ser reembolsado.
        </p>
        <p>
        El intermediario se reserva el derecho a rescindir el contrato si:
        </p>
        <p>
            - el servicio no puede prestarse en las condiciones originales por razones objetivas,
        </p>
        <p>
            - no se ha facilitado previamente toda la información pertinente sobre el alcance y las especificaciones del servicio,
        </p>
        <p>
            - la ejecución se hace objetivamente imposible o ilegal,
        </p>
        <p>
            - en caso de incumplimiento material de estas condiciones por parte del interesado.
        </p>
        <p>
        En el caso de que se produzca alguno de los hechos mencionados, el corredor informará inmediatamente al interesado del desistimiento del contrato al correo electrónico desde el que se envió la orden. El desistimiento es efectivo frente al interesado en el momento en que se le entrega. El intermediario deberá devolver el pago recibido sin efectivo a la cuenta desde la que el interesado realizó el pago en un plazo de 5 días desde la retirada.
        </p>
        <p>
            <strong>Información sobre la protección de datos personales</strong>
        </p>
        <p>
        El intermediario cumplirá con su obligación de información hacia el cliente potencial en el sentido del artículo 13 del Reglamento 2016/679 del Parlamento Europeo y del Consejo relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protección de datos) (en adelante, el "RGPD") en relación con el tratamiento de los datos personales del cliente potencial a efectos de la ejecución del Contrato de Mediación de Servicios, a efectos de la negociación del Contrato de Mediación de Servicios y a efectos del cumplimiento de las obligaciones públicas del intermediario mediante un documento independiente.
        </p>
        <p>
            <strong>Otros datos</strong>
        </p>
        <p>
        Para la celebración del contrato se utilizan medios de comunicación a distancia. Los gastos derivados de la utilización de las vías de comunicación a distancia correrán a cargo del interesado. Toda la correspondencia relativa al contrato entre el intermediario y el cliente potencial se realizará por escrito, por correo electrónico.
        </p>
        <p>El interesado reconoce que el software y otros componentes que forman la interfaz web de la tienda (incluidas las fotografías de los servicios ofrecidos) están protegidos por derechos de autor. El solicitante se compromete a no realizar ninguna acción que pueda permitirle a él o a terceros interferir o hacer un uso no autorizado del software u otros componentes de la interfaz web de la tienda.
        </p>
        <p>Al utilizar la interfaz web de la tienda, el interesado no tiene derecho a utilizar mecanismos, software u otros procedimientos que puedan afectar negativamente al funcionamiento de la interfaz web. La interfaz web de la tienda sólo podrá utilizarse en la medida en que no perjudique los derechos de otros clientes del intermediario y que sea coherente con su finalidad.
        </p>
        <p>El interesado reconoce que el intermediario no es responsable de los errores derivados de la interferencia de terceros en el sitio web o del uso del sitio web contrario al previsto.
        </p>
        <p>En el caso de que cualquier disposición de estos Términos y Condiciones sea (o llegue a ser) inválida, ineficaz o inaplicable, se aplicará en su lugar la disposición que más se aproxime a la disposición inválida, ineficaz o inaplicable. La invalidez, ineficacia o inaplicabilidad de una disposición no afectará a la validez de las demás disposiciones.
        </p>
        <p>
        Estos términos y condiciones son válidos y efectivos a partir del 16.12.2021.
        </p>
        <p>
            Goodbye s.r.o.
        </p>
        </div>
      </OpKremaceInner>
    </Layout>
  );
}



export default ObchodniPodminkyKremace;


