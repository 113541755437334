import React from 'react';
import { Link } from 'gatsby';
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';


const BlockInner = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 10000000;
  overflow: hidden;
  top: 0;
  left: 0;

  span {
    text-align: center;
    font-family: Ivar;
    font-size: 32px;
    color: #000000;
    margin-bottom: 80px;
    width: calc(100% - 100px);
    margin: 0 auto;
    display: block;
    margin-top: 100px;
  }

  p {
    text-align: center;
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #585858;
    line-height: 24px;
    margin-top: 15px;
    margin-bottom: 20px;
    width: calc(100% - 100px);
    margin: 20px auto;
    margin-bottom: 50px;
    display: block;
  }

  a {
    font-family: Visuelt-Medium;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    width: calc(100%);
    max-width: 290px;
    margin: 20px auto;
    display: block;
    background: #1D3A5B;
    border-radius: 3px;
    padding: 19px 0;
    -webkit-text-decoration: none;
    text-decoration: none;
  }
`;

const Block = () => {

  
  return (
    <BlockInner>
      <span>Omlouváme se, bohužel nejsme dočasně k dispozici.</span>
      <p>Pokud máte jakékoliv dotazy, kontaktujte nás prosím na čísle XXX XXX XXX nebo podpora@goodbye.cz</p>
      <Link to="/zaveti">Tvorba závětí</Link>
      <Link to="/poradna">Poradna</Link>
    </BlockInner>
  );
}

export default Block;
